import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import TitleBlockComponent, {
  PageStaticTitle,
  TaskStaticTitle,
} from "screens/base/main/detailView/documentContainer/document/lineComponent/lineVariants/TitleBlockComponent";
import styles from "./containerTitleBlockWrapper/containerTitleBlockWrapper.module.scss";
import { ClarityStore } from "store/storeExporter";
import {
  ContainerTypes,
  IBlockContext,
  WorkTypes,
  IProjectObj,
  Abilities,
} from "utilities/types";
import { pick } from "lodash";
import Conditional from "components/Conditional";
import { useShallowSelector } from "utilities/hooks";
import { useParentClickHandler } from "clarity-ui/ContainerDetailMetadata";
import { FileDoneOutlined } from "@ant-design/icons";
import { useAbilityChecker } from "editor/utils/customHooks";
import { getGroupsFromEntity } from "modules/containerHelpers";
import { ChunkDestination } from "utilities/stateTypes";

interface TitleWrapperProps {
  containerId: string;
  containerType: string;
  paneId: ChunkDestination;
  context: IBlockContext;
  isBaseMember: boolean;
}

const ContainerTitleBlockWrapper: React.FC<TitleWrapperProps> = (props) => {
  const wrapperProps = pick(props, [
    "containerId",
    "paneId",
    "isBaseMember",
    "context",
  ]);
  const isDocument = props.containerType === ContainerTypes.DOCUMENT;
  const isWork = props.containerType === ContainerTypes.PROJECT;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const Wrapper = (wrapperProps: any) =>
    isDocument ? (
      <PageTitleWrapper {...wrapperProps} />
    ) : isWork ? (
      <TaskTitleWrapper {...wrapperProps} />
    ) : null;

  return (
    <>
      <Conditional on={isDocument}>
        <PageTitleWrapper {...wrapperProps} />
      </Conditional>
      <Conditional on={isWork}>
        <WorkTitle titleWrapperProps={wrapperProps} />
      </Conditional>
    </>
  );
};

interface IWorkTitleProps {
  titleWrapperProps: ITaskTitleWrapperProps;
}

function WorkTitle(props: IWorkTitleProps) {
  const { titleWrapperProps } = props;
  const {
    item,
    isInitiative,
    parentName,
    parentWorkId,
    handleParentClick,
    hasParent,
  } = useWorkTitleState(props);

  return (
    <div className={styles.workWrapperContainer}>
      <div className={styles.workWrapperBottom}>
        <Conditional on={hasParent}>
          <>
            <Conditional on={isInitiative}>
              <div className={styles.workWrapperHead}></div>
            </Conditional>
            <Conditional on={!isInitiative}>
              <div
                className={`${styles.workTypeContainer}`}
              >
                <span
                  className={`${styles.workType}`}
                >
                  {hasParent ? <FileDoneOutlined /> : ""}
                </span>
              </div>
            </Conditional>
            <div
              className={`${styles.parentContainer}`}
              onClick={handleParentClick}
            >
              <Conditional on={parentName && parentWorkId}>
                <div>
                  <span className={styles.parent}>{parentName}</span>
                </div>
                <div>
                  <span className={styles.parentWorkId}>#{parentWorkId}</span>
                </div>
              </Conditional>
            </div>
          </>
        </Conditional>
      </div>
      <div className={styles.workWrapperTop}>
        <TaskTitleWrapper {...titleWrapperProps} />
      </div>
    </div>
  );
}

function useWorkTitleState({ titleWrapperProps }: IWorkTitleProps) {
  const { containerId } = titleWrapperProps;
  const item = useShallowSelector((state) => state.work.dict[containerId]);
  const { workType, parentId } = item;
  const isInitiative = workType === WorkTypes.INITIATIVE;
  const parent = useShallowSelector((state) =>
    parentId ? state.work.dict[parentId] ?? {} : ({} as IProjectObj)
  );
  const { name: parentName, projectId: parentWorkId } = parent;
  const hasParent = parentName && parentWorkId;

  const handleParentClick = useParentClickHandler({
    ...pick(titleWrapperProps, ["paneId"]),
    container: item,
  });

  return {
    item,
    workType,
    isInitiative,
    parentName,
    parentWorkId,
    handleParentClick,
    hasParent,
  };
}

const PageTitleWrapper: React.FC<{
  containerId: string;
  context: IBlockContext;
}> = ({ containerId, context }) => {
  const pageItem = useSelector(
    (store: ClarityStore) => store.pages.dict[containerId],
    shallowEqual
  );
  const titleBlock = useSelector(
    (store: ClarityStore) =>
      pageItem?.id && pageItem?.titleBlockId
        ? store.blocks.dict[pageItem.titleBlockId]
        : null,
    shallowEqual
  );

  if (titleBlock) {
    return (
      <TitleBlockComponent
        document={pageItem}
        titleBlock={titleBlock}
        context={context}
        isBaseMember={context.canEdit}
      />
    );
  } else if (pageItem.nameValue)
    return <PageStaticTitle value={pageItem.nameValue} />;
  return <></>;
};

interface ITaskTitleWrapperProps {
  containerId: string;
  paneId: ChunkDestination;
  isBaseMember: boolean;
  context: IBlockContext;
}

function TaskTitleWrapper({ containerId, context }: ITaskTitleWrapperProps) {
  const workItem = useSelector(
    (store: ClarityStore) => store.work.dict[containerId],
    shallowEqual
  );
  const titleBlock = useSelector(
    (store: ClarityStore) =>
      workItem?.id ? store.blocks.dict[workItem.titleBlockId] : null,
    shallowEqual
  );

  if (titleBlock) {
    return (
      <TitleBlockComponent
        slim
        project={workItem}
        titleBlock={titleBlock}
        context={context}
        isBaseMember={context.canEdit}
      />
    );
  } else if (workItem?.nameValue)
    return <TaskStaticTitle value={workItem?.nameValue} />;
  return <></>;
}

export default ContainerTitleBlockWrapper;
