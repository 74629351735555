import React, { useEffect, useState } from "react";
import { batch, connect } from "react-redux";
import styles from "./workItem/workItem.module.scss";
import { Draggable } from "react-beautiful-dnd";
import { stripHtml } from "utilities/stringUtilities";
import { WorkEntry } from "store/reducers/workReducer";
import {
  CommandPaletteContext,
  ContainerTypes,
  IWorkspaceObj,
  ViewNames,
} from "utilities/types";
import { useHistory } from "react-router-dom";
import { SHOW_COMMAND_PALETTE } from "store/actions";
import store from "store/storeExporter";
import { ChunkDestination } from "utilities/stateTypes";
import StatusDisplay from "components/StatusDisplay";
import LoadingSpinner from "components/LoadingSpinner";
import DueDateDisplay from "clarity-ui/DueDateDisplay";
import { getHtml } from "editor/utils/blockValueHelpers";
import { getCorrectLink } from "utilities/linkUtilities";
import navigationApi from "clientApi/navigationApi";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";

interface IWorkItemProps {
  key: any;
  item: any;
  index: number;
  handleTaskClick: any;
  reorder?: boolean;
  type?: string;
  disableNavigation?: boolean;
  isWorkBlock?: boolean;
  destination?: string;
}

interface IMapStateToProps {
  workDict: WorkEntry;
  base: IWorkspaceObj;
}

interface IMapDispatchToProps {}

function WorkItem(
  props: IWorkItemProps & IMapDispatchToProps & IMapStateToProps
) {
  const {
    key,
    item,
    index,
    workDict,
    base,
    handleTaskClick,
    reorder,
    isWorkBlock,
  } = props;
  const history = useHistory();
  const [isConfirmed, setisConfirmed] = useState(item.projectId !== 0);

  useEffect(() => {
    setisConfirmed(item.projectId !== 0);
  }, [item.projectId]);

  const handleWorkItemClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.disableNavigation || !isConfirmed) return;
    if (props.destination) {
      if (props.destination === "primary") {
        if (e.shiftKey) {
          return navigationApi.openSplitView({
            viewName: ViewNames.Detail,
            containerId: item.id,
            containerType: ContainerTypes.WORK,
            workType: item.workType,
          });
        }
        if (handleTaskClick && item.workType) return handleTaskClick(item);
        else {
          return history.push(getCorrectLink(base, item));
        }
      }
      if (props.destination === ChunkDestination.secondary) {
        if (e.shiftKey) {
          return history.push(getCorrectLink(base, item));
        }
        if (handleTaskClick) return handleTaskClick(item);
        else {
          return navigationApi.openSplitView({
            viewName: ViewNames.Detail,
            containerId: item.id,
            containerType: ContainerTypes.WORK,
            workType: item.workType,
          });
        }
      }
    }
    if (e.shiftKey) {
      return navigationApi.openSplitView({
        viewName: ViewNames.Detail,
        containerId: item.id,
        containerType: ContainerTypes.WORK,
        workType: item.workType,
      });
    }
    if (handleTaskClick) return handleTaskClick(item);
    else {
      return history.push(getCorrectLink(base, item));
    }
  };

  return reorder ? (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <div
          key={key}
          className={`${styles.workItem} ${
            item.isClosed ? styles.workItem_closed : ""
          }`}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          // onClick={handleWorkItemClick}
        >
          <div className={styles.workItem_head}>
            <div className={styles.entityIconContainer}>
              <div
                className={styles.entityIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  batch(() => {
                    if (props.handleTaskClick) {
                      store.dispatch({
                        type: SHOW_COMMAND_PALETTE,
                        context: CommandPaletteContext.STATUS,
                        params: {
                          selectedItemIds: [item.id],
                          slectedItemsType: ContainerTypes.WORK,
                        },
                      });
                    }
                  });
                }}
              >
                <StatusDisplay
                  showName={false}
                  statusId={item.statusId}
                  workType={item.workType}
                  size={isWorkBlock ? "large" : "small"}
                />
              </div>
            </div>
            <span
              onClick={(e) => handleWorkItemClick(e)}
              className={styles.workNrLink}
            >{`#${item.projectId}`}</span>
          </div>
          <div className={styles.workItem_body}>
            <span
              className={"caption medium " + styles.workItemCaption}
              style={{ fontWeight: 500 }}
              onClick={(e) => handleWorkItemClick(e)}
            >
              {item.name}
            </span>
            {item.parentId && workDict[item.parentId] && (
              <span className="caption disabled" style={{ marginLeft: "8px" }}>
                {stripHtml(getHtml(workDict[item.parentId].nameValue))}
              </span>
            )}
          </div>
          <div className={styles.workItem_tail}>
            <div className={styles.workItem_tail_deadlineContainer}>
              <DueDateDisplay dueDate={item.dueDate} isClosed={item.isClosed} />
            </div>
            <div className={styles.workItem_tail_assigneeContainer}>
              {item.assignee && (
                <img
                  src={item.assignee.avatar}
                  width={"24px"}
                  height={"24px"}
                  alt="assignee avatar"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  ) : (
    <div
      key={key}
      className={`${styles.workItem} ${
        item.isClosed || !isConfirmed ? styles.workItem_closed : ""
      } ${isWorkBlock && styles.workItem__workBlock}`}
      // onClick={handleWorkItemClick}
      style={{ paddingTop: "1px", paddingBottom: "1px" }}
    >
      <div className={styles.workItem_body}>
        <div
          className={styles.entityIconContainer}
          style={{ paddingTop: "0px" }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (props.handleTaskClick) {
                openCommandPalette(CommandPaletteContext.STATUS, {
                  selectedItemIds: [item.id],
                  slectedItemsType: ContainerTypes.WORK,
                });
              }
            }}
          >
            <StatusDisplay
              showName={false}
              statusId={item.statusId}
              workType={item.workType}
            />
          </div>
        </div>
        <div className={styles.workItem_head}>
          <span
            onClick={(e) => handleWorkItemClick(e)}
            className={styles.workNrLink}
          >
            {" "}
            {isConfirmed ? (
              `#${item.projectId}`
            ) : (
              <LoadingSpinner style={{ height: "1.2em", width: "1.2em" }} />
            )}
          </span>
        </div>
        {/* {props.type === "link" && (
          <div style={{ marginLeft: "-2px", marginTop: "-1px" }}>
            <LinkIcon />
          </div>
        )} */}
        <div>
          <span
            className={"caption medium " + styles.workItemCaption}
            style={{ fontWeight: 500 }}
            onClick={(e) => handleWorkItemClick(e)}
          >
            {item.name}
          </span>
          {item.parentId && workDict[item.parentId] && props.type === "link" && (
            <span
              className={`caption disabled ${styles.workItem_parentName}`}
              style={{ marginLeft: "8px" }}
            >
              {stripHtml(getHtml(workDict[item.parentId].nameValue))}
            </span>
          )}
        </div>
      </div>
      <div className={styles.workItem_tail}>
        <div className={styles.workItem_tail_deadlineContainer}>
          <DueDateDisplay dueDate={item.dueDate} isClosed={item.isClosed} />
        </div>
        <div className={styles.workItem_tail_assigneeContainer}>
          {item.assignee && (
            <img
              src={item.assignee.avatar}
              width={"24px"}
              height={"24px"}
              alt="assignee avatar"
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  workDict: state.work.dict,
  base: state.workspace,
});

const mapDispatchToProps = (dispatch: any) => ({});

/* » */

export const LinkIcon: React.FC<any> = () => {
  return (
    <div style={{ width: "16px", display: "contents" }}>
      <svg
        style={{
          height: "25px",
          width: "25px",
          paddingTop: "6px",
          opacity: ".3",
        }}
      >
        <path d="M13.646 10.879V3.084c0-.473-.298-.788-.78-.788l-7.794.016c-.465 0-.764.34-.764.73 0 .39.34.723.73.723h2.466l3.951-.15-1.502 1.329-7.413 7.429a.733.733 0 00-.232.506c0 .39.348.764.755.764.19 0 .365-.075.515-.224l7.42-7.43 1.337-1.502-.158 3.777v2.648c0 .382.332.73.739.73.39 0 .73-.323.73-.763z"></path>
      </svg>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkItem);
