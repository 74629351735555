import React, { useEffect, useMemo, useRef, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styles from "./main/main.module.scss";
import {
  BaseType,
  ContainerTypes,
  GeneralViewsNames,
  TasksViewModes,
  ViewNames,
  WorkTypes,
} from "utilities/types";
import ErrorListener from "components/ErrorListener";
import triangleSVG from "icons/disclosure-triangle.svg";
import sixDotSVG from "icons/six-dot-handle.svg";
import TasksViewRouter from "./main/TasksViewRouter";
import workItemStyles from "../../clarity-ui/workItem/workItem.module.scss";
import { ChunkDestination } from "utilities/stateTypes";
import GroupRouter from "components/GroupRouter";
import NavigationContext from "./NavigationContext";
import DetailRouter from "components/DetailRouter";
import { useContainerWidth, useShallowSelector } from "utilities/hooks";
import BaseTypeSwitcher from "./BaseTypeSwitcher";
import GlovalTopNav from "components/GlobalTopNav";
import TopNavBar, { ExternalBtns } from "components/TopNavBar";
import Conditional from "components/Conditional";
import { PageTitle } from "components/topNavBar/PageTitle";
import ClaritySidebar from "./ClaritySidebar";
import { PaneContext, SizeContext } from "App";
import ErrorTopBar from "components/ErrorTopBar";
import PayoutRouter from "components/PayoutRouter";

export const TriangleIcon = triangleSVG;

export const HandleIcon = sixDotSVG;

export const MainViewRef: React.MutableRefObject<HTMLDivElement | null> = {
  current: null,
};

function Main() {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const baseType = useShallowSelector((store) => store.workspace.type);
  const isBaseMember = useShallowSelector((store) => store.client.isBaseMember);
  const widthStyles = useWidthStyles(containerRef);
  const widthMode = useContainerWidth(containerRef);

  return useMemo(() => {
    return (
      <PaneContext.Provider value={ChunkDestination.primary}>
        <SizeContext.Provider value={widthMode}>
          <div
            className="flex-fill"
            ref={containerRef}
            style={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <GlovalTopNav widthMode={widthMode} />
            <ErrorTopBar key="main" paneId={ChunkDestination.primary} />
            <div className="flex-fill" style={{ overflow: "hidden" }}>
              <div className="full-flex">
                <Conditional on={isBaseMember || baseType === BaseType.Public}>
                  <ClaritySidebar />
                </Conditional>
                <div
                  className={`${styles.mainContainer} ${widthStyles.join(
                    " "
                  )} flex-fill main`}
                  style={{ display: "flex" }}
                  id="primary"
                  ref={MainViewRef}
                >
                  <MainViewRoutes />
                </div>
              </div>
            </div>
          </div>
        </SizeContext.Provider>
      </PaneContext.Provider>
    );
  }, [widthStyles, baseType, widthMode]);
}

const MainViewRoutes: React.FC = () => {
  const baseType = useShallowSelector((store) => store.workspace.type);
  const isBaseMember = useShallowSelector((store) => store.client.isBaseMember);

  return (
    <>
      <Conditional on={!isBaseMember && baseType !== BaseType.Public}>
        <FocusViewTopBar />
      </Conditional>
      <Conditional on={isBaseMember || baseType === BaseType.Public}>
        <TopNavBar paneId={ChunkDestination.primary} />
      </Conditional>

      <ErrorListener />
      {isBaseMember || baseType === BaseType.Public ? (
        <Switch>
          <Route
            path={"/discord-integration"}
            exact={true}
            strict={true}
            render={(props) => {
              return <></>;
            }}
          />
          <Route
            path={[
              "/:baseSlug/",
              "/:baseSlug/home",
              "/:baseSlug",
              "/:baseSlug/home/",
            ]}
            strict={true}
            exact={true}
            render={() => {
              return <NavigationContext viewName={GeneralViewsNames.Home} />;
            }}
          />
          <Redirect from="/:baseSlug/the-weekly" to="/:baseSlug/weekly" />
          <Route
            path={["/:baseSlug/weekly"]}
            strict={true}
            render={() => {
              return (
                <NavigationContext viewName={GeneralViewsNames.TheWeekly} />
              );
            }}
          />
          <Route
            path={["/:baseSlug/group/:groupSlug"]}
            strict={true}
            render={() => {
              return <GroupRouter />;
            }}
          />
          <Route
            path="/:baseSlug/tasks"
            render={(props) => {
              return <TasksViewRouter />;
            }}
          />
          <Route
            path="/:baseSlug/my-work/projects/archive"
            render={(props) => {
              return (
                <NavigationContext
                  viewName={GeneralViewsNames.MyProjectsArchive}
                />
              );
            }}
          />
          <Route
            path="/:baseSlug/my-work/projects"
            render={(props) => {
              return (
                <NavigationContext viewName={GeneralViewsNames.MyProjects} />
              );
            }}
          />
          <Route
            path="/:baseSlug/my-work/reviewing"
            render={(props) => {
              return (
                <NavigationContext
                  viewName={GeneralViewsNames.Reviewing}
                  taskViewMode={TasksViewModes.Reviewing}
                />
              );
            }}
          />
          <Route
            path="/:baseSlug/my-work"
            render={(props) => {
              return (
                <NavigationContext
                  viewName={GeneralViewsNames.MyTasks}
                  taskViewMode={TasksViewModes.MyTasks}
                />
              );
            }}
          />
          <Route path="/:baseSlug/payouts" component={PayoutRouter} />
          <Route
            path={[
              "/:baseSlug/notes/:containerId",
              "/:baseSlug/docs/:containerId",
              "/:baseSlug/project/:containerId",
              "/:baseSlug/work/:groupContainerId",
              "/:baseSlug/pages/:containerId",
              "/:baseSlug/tags/:containerId",
              "/:baseSlug/cycle/:cycleId",
              "/:baseSlug/sprint/:cycleId",
              "/:baseSlug/view/:viewId",
              "/:baseSlug/template/:templateId",
              "/:baseSlug/snippet/:snippetId",
            ]}
            component={DetailRouter}
          />

          <Route
            path="/:baseSlug/roadmap"
            render={(props) => {
              return <NavigationContext viewName={GeneralViewsNames.Roadmap} />;
            }}
            exact
          />

          <Route
            path="/:baseSlug/roles"
            render={(props) => {
              return <NavigationContext viewName={GeneralViewsNames.Roles} />;
            }}
            exact
          />

          <Route
            path="/:baseSlug/groups"
            render={(props) => {
              return <NavigationContext viewName={GeneralViewsNames.Groups} />;
            }}
            exact
          />

          <Route
            path="/:baseSlug/views"
            render={(props) => {
              return <NavigationContext viewName={GeneralViewsNames.Views} />;
            }}
            exact
          />

          <Route
            path="/:baseSlug/templates"
            render={(props) => {
              return (
                <NavigationContext viewName={GeneralViewsNames.Templates} />
              );
            }}
            exact
          />

          <Route
            path="/:baseSlug/snippets"
            render={(props) => {
              return (
                <NavigationContext viewName={GeneralViewsNames.Snippets} />
              );
            }}
            exact
          />

          <Route
            path="/:baseSlug/roadmap/archive"
            render={(props) => {
              return (
                <NavigationContext
                  viewName={GeneralViewsNames.RoadmapArchive}
                />
              );
            }}
          />
          <Redirect from="/:baseSlug/pages" to="/:baseSlug/tags" />
          <Route
            path="/:baseSlug/tags"
            exact={true}
            render={(props) => {
              return <NavigationContext viewName={GeneralViewsNames.Wiki} />;
            }}
          />
        </Switch>
      ) : (
        <Switch>
          <Route
            path={"/discord"}
            exact={true}
            strict={true}
            render={(props) => {
              return <></>;
            }}
          />
          <Redirect
            from="/:baseSlug/notes/:containerId"
            to="/:baseSlug/docs/:containerId"
          />
          <Route
            path="/:baseSlug/docs/:containerId"
            render={(props) => (
              <NavigationContext
                viewName={ViewNames.Detail}
                containerId={props.match.params.containerId}
                containerType={ContainerTypes.NOTE}
              />
            )}
          />
          <Route
            path="/:baseSlug/project/:containerId"
            render={(props) => (
              <NavigationContext
                viewName={ViewNames.Detail}
                containerId={props.match.params.containerId}
                containerType={ContainerTypes.WORK}
                workType={WorkTypes.PROJECT}
              />
            )}
          />
          <Route
            path="/:baseSlug/work/:groupContainerId"
            render={(props) => (
              <NavigationContext
                viewName={ViewNames.Detail}
                containerId={props.match.params.groupContainerId}
                containerType={ContainerTypes.WORK}
                workType={WorkTypes.TASK}
              />
            )}
          />
          <Redirect
            from="/:baseSlug/pages/:containerId"
            to="/:baseSlug/tags/:containerId"
          />
          <Route
            path="/:baseSlug/tags/:containerId"
            render={(props) => (
              <NavigationContext
                viewName={ViewNames.Detail}
                containerId={props.match.params.containerId}
                containerType={ContainerTypes.DOCUMENT}
              />
            )}
          />
          <Route
            path={[
              "/:baseSlug/notes",
              "/:baseSlug/docs",
              "/:baseSlug/pages",
              "/:baseSlug/tags",
              "/:baseSlug/work",
              "/:baseSlug/settings",
              "/:baseSlug",
            ]}
            render={({ match }) => <BaseTypeSwitcher />}
          />
        </Switch>
      )}
    </>
  );
};

const FocusViewTopBar: React.FC = React.memo(() => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        height: "54px",
        justifyContent: "space-between",
        position: "absolute",
        top: 0,
        zIndex: 99,
        padding: "10px",
      }}
    >
      <PageTitle paneId={ChunkDestination.primary} />
      <ExternalBtns paneId={ChunkDestination.primary} />
    </div>
  );
});

export default Main;

const useRefWidth = (ref: any) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setInterval(() => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    }, 400);
  }, []);
  return width;
};

export const useWidthStyles = (ref: any) => {
  const width = useRefWidth(ref);
  const [widthStyles, setWidthStyles] = useState<string[]>([]);
  useEffect(() => {
    setWidthStyles([]);
    if (width <= 620) {
      setWidthStyles([
        workItemStyles.nineSixtyOrLess,
        workItemStyles.sixTwentyOrLess,
        "nineSixtyOrLess",
      ]);
    } else if (width <= 960) {
      setWidthStyles([workItemStyles.nineSixtyOrLess, "nineSixtyOrLess"]);
    }
  }, [width]);
  return widthStyles;
};
